* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  overscroll-behavior-y: none;
  overflow: hidden;
}

body {
  color: #000;
  background-color: #595959;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .8125rem;
}

button, select {
  font-family: inherit;
  font-size: inherit;
  margin: 2px 0;
}

input[type="range"] {
  margin: 2px 0;
}

input[type="file"] {
  display: none;
}

.message-dialog-overlay {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.message-dialog {
  z-index: 11;
  flex-direction: column;
  display: flex;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, .4);
}

.message-dialog-inner {
  min-width: 20em;
  max-width: 75vw;
  padding: 1em;
  overflow: hidden;
}

.message-dialog-inner button {
  margin-top: 1em;
}

.align-right {
  text-align: right;
}

.panel {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  border-color: #d7d7d7 #c9c9c9 #bbb;
  border-radius: 6px;
  overflow: hidden;
}

.panel .handle {
  height: 2em;
  cursor: move;
  background-color: #ededed;
  border: none;
  border-top: 1px solid #fefefe;
  border-radius: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4px;
  display: flex;
}

.panel .handle button {
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  vertical-align: middle;
  padding: 0 2px;
  font-family: inherit;
  font-size: 12px;
}

.control-panel {
  min-width: 20em;
  z-index: 1;
  position: absolute;
  top: 1em;
  left: 1em;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, .4);
}

.control-panel-inner {
  padding: 4px;
}

.label-input-grid {
  grid-column-gap: 1em;
  grid-template-columns: 1fr 2fr;
  align-self: center;
  align-items: center;
  display: grid;
}

.label-input-grid-item {
  text-align: right;
  width: 100%;
}

.label-input-grid-item select, .label-input-grid-item input {
  width: 100%;
}

.button-grid {
  grid-column-gap: 0;
  grid-template-columns: 1fr;
  display: grid;
}

.button-grid-item, .button-grid-item button {
  width: 100%;
}

.imageviewer-border {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: #5955e0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.imageviewer-canvas {
  height: 95%;
  aspect-ratio: 4 / 3;
  background-color: #010101;
  margin: auto;
  overflow: hidden;
}

.imageviewer-canvas img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=index.879d0f5b.css.map */
